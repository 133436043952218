/* eslint-disable */
import moment from "moment";

function updateItem(item: any, selected: number, yesColor: string, notColor: string) {
  item.selected = selected;
  item.yesColor = yesColor;
  item.notColor = notColor;
}

export default {
  onClickNull(item: any) {
    updateItem(item, 0, "gainsboro", "gainsboro");
  },

  onClickYes(item: any) {
    if (item.selected == 1) {
      updateItem(item, 0, "gainsboro", "gainsboro");
    } else {
      updateItem(item, 1, "success", "gainsboro");
    }
  },

  onClickNot(item: any) {
    if (item.selected == 2) {
      updateItem(item, 0, "gainsboro", "gainsboro");
    } else {
      updateItem(item, 2, "gainsboro", "danger");
    }
  },

  //定时关闭刷新动画
  closeRefreshAnimation(RefreshAnimationEvent: any, num: number) {
    num = num || 2000;
    setTimeout(() => {
      if (RefreshAnimationEvent) {
        RefreshAnimationEvent.target.complete();
      }
    }, num);
  },
  getWeek (date: string | number) { // 参数时间戳
    let week = moment(date).day()
    switch (week) {
      case 1:
        return '周一'
      case 2:
        return '周二'
      case 3:
        return '周三'
      case 4:
        return '周四'
      case 5:
        return '周五'
      case 6:
        return '周六'
      case 0:
        return '周日'
    }
  },
};
