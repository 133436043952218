export function handleDecodeUrlComponentStr(str: any) {
  return str ? JSON.parse(decodeURIComponent(str)) : { aptmId: "" };
}

/**
 * @param key - 数据存储key
 * @param defaultTypeData - 当不存在key时，存储key的默认值
 * */
export function getLocalStorage(key: string, defaultTypeData: any) {
  const content = localStorage.getItem(key);
  if (content) {
    return JSON.parse(content);
  }
  return defaultTypeData;
}

export function localStorageContent(key: string, content: any) {
  localStorage.setItem(key, JSON.stringify(content));
}

export function sheetTimeList() {
  const arr = [];
  for (let i = 10; i < 47; i++) {
    if (i % 2 !== 0) {
      arr.push("");
    } else {
      arr.push(i / 2 + ":00");
    }
  }
  return arr;
}

export function startTimeList() {
  const arr = [];
  for (let i = 5; i < 24; i++) {
    arr.push({ label: i + ":00" });
    arr.push({ label: i + ":30" });
  }
  return arr;
}

export function endTimeList(startTime: string) {
  let startArr = [];
  const resultArr = [];
  if (startTime) {
    startArr = startTime.split(":");
    if (startArr.length == 2) {
      const n = parseInt(startArr[0]);
      for (let i = n + 1; i < 24; i++) {
        resultArr.push({ label: i + ":00" });
        resultArr.push({ label: i + ":30" });
      }
    }
  }
  return resultArr;
}
