<template>
  <ion-page
    class="box flex-column align-center justify-between"
    :class="aiTheme"
    :style="{ '--top': type == 'hasCancel' ? '15px' : '0' }"
  >
    <!-- <img @click="onClose" class="close-pic" src="../assets/teacherFeed1.png" alt=""> -->
    <div class="title">{{ title || "提示信息" }}</div>
    <div v-if="hasHtml" id="txtClipboard" class="content-value" v-html="message"></div>
    <div v-else id="txtClipboard" class="content-value">{{ message || " " }}</div>
    <!-- <div class="btn-box" v-if="type == 'hasCancel'">
      <div  class="btn1"   @click="cancel">取消</div>
      <div  class="btn2"   @click="confirm">确定</div>
    </div> -->
    <div class="btnClipboard cursor-pointer" @click="confirm">确定</div>
    <div v-if="type == 'hasCancel'" class="btn1" @click="cancel">取消</div>
  </ion-page>
</template>
<script>
import { IonPage, modalController } from "@ionic/vue";

import { chevronBackOutline } from "ionicons/icons";

export default {
  name: "EduPrintAddress",
  components: {
    IonPage,
  },
  props: ["title", "message", "type", "hasHtml"],
  setup() {
    return {
      chevronBackOutline,
    };
  },
  data() {
    return {
      printAddress: undefined,
      aiTheme: undefined,
      generateContent: null,
    };
  },
  mounted() {
    this.generateContent = localStorage.getItem("GenerateContent");
    if (this.generateContent) {
      this.aiTheme = localStorage.getItem("ai-theme");
    } else {
      this.aiTheme = undefined;
    }
  },
  methods: {
    async confirm() {
      await modalController.dismiss("NEXT");
    },
    async cancel() {
      await modalController.dismiss();
    },
  },
};
</script>

<style scoped lang="less">
@import "@/theme/ai-theme.less";

.box {
  height: 230px;
  padding: 25px 20px 25px 20px;
}
.top {
  padding: var(--top);
}
.address input {
  border: 0;
  width: 100%;
}

.btnClipboard {
  width: 100%;
  height: 80%;
}
/* new ui */
.ion-page {
  justify-content: flex-start;
}
.close-pic {
  width: 18px;
  height: 18px;
  display: block;
  margin-left: auto;
}
.title {
  color: #262626;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 1rem;
}
.content-value {
  color: #868f9e;
  font-size: 14px;
  text-align: center;
  white-space: pre-wrap;
}
.btnClipboard {
  margin: 25px auto 0;
  width: 180px;
  height: 44px;
  border-radius: 25px;
  background: var(--text-import-color, #2bca9c);
  line-height: 44px;
  color: #fff;
  text-align: center;
  font-size: 17px;
}
.btn-box {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  width: 100%;
  margin-bottom: 20px;
}
.btn1 {
  width: 180px;
  /* line-height: px; */
  color: #868f9e;
  text-align: center;
  font-size: 14px;
  margin: 3vw auto 0;
}
.btn2 {
  width: 80px;
  border-radius: 15px;
  background: var(--text-import-color, #2bca9c);
  line-height: 44px;
  color: #fff;
  text-align: center;
  font-size: 17px;
}
</style>
