<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom" class="bottom-tabbar">
        <ion-tab-button tab="tab0" href="/StudentIndex" @click="changeBtn(1)">
          <!-- <ion-icon :icon="homeOutline"/> -->
          <img v-if="!isOne" class="pic" src="@/assets/student/version5/student_home_tab.svg" alt="" />
          <img v-else class="pic" src="@/assets/student/version5/student_clicked_home.svg" alt="" />
          <ion-label class="label" :class="{ active: isOne }">首页</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="tab1" href="/PersonalClassRecord" @click="changeBtn(2)">
          <!-- <ion-icon :icon="timerOutline"/> -->
          <img v-if="!isTwo" class="pic" src="@/assets/student/version5/student_record_tab.svg" alt="" />
          <img v-else class="pic" src="@/assets/student/version5/student_clicked_record.svg" alt="" />
          <ion-label class="label" :class="{ active: isTwo }">训练记录</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="tab2" href="/StudentRanking" @click="changeBtn(3)">
          <!-- <ion-icon :icon="listOutline"/> -->
          <img v-if="!isThree" class="pic" src="@/assets/student/version5/student_rank_tab.svg" alt="" />
          <img v-else class="pic" src="@/assets/student/version5/student_clicked_rank.svg" alt="" />
          <ion-label class="label" :class="{ active: isThree }">排行榜</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="tab4" href="/StudentPersonalIndex" @click="changeBtn(4)">
          <!-- <ion-icon :icon="personOutline"/> -->
          <img v-if="!isFour" class="pic" src="@/assets/teacher_index/version5/user_tab.svg" alt="" />
          <img v-else class="pic" src="@/assets/teacher_index/version5/clicked_user_tab.svg" alt="" />
          <ion-label class="label" :class="{ active: isFour }">个人中心</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonPage, IonRouterOutlet } from "@ionic/vue";
import {
  timerOutline,
  libraryOutline,
  personOutline,
  listOutline,
  homeOutline,
} from "ionicons/icons";
import { getBrandImageUrl } from "@/js/brandImages";

export default {
  name: "StudentTabs",
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonPage,
    IonRouterOutlet,
  },
  setup() {
    return {
      timerOutline,
      libraryOutline,
      personOutline,
      homeOutline,
      listOutline,
    };
  },

  data() {
    return {
      isOne: false,
      isTwo: false,
      isThree: false,
      isFour: false,
    };
  },
  mounted() {
    console.log("mounted", this.$route);
    if (this.$route.path == "/StudentIndex") {
      this.isOne = true;
    }
    if (this.$route.path == "/PersonalClassRecord") {
      this.isTwo = true;
    }
    if (this.$route.path == "/StudentRanking") {
      this.isThree = true;
    }
    if (this.$route.path == "/StudentPersonalIndex") {
      this.isFour = true;
    }
  },
  methods: {
    getBrandImageUrl,
    changeBtn(type) {
      if (type == 1) {
        if (this.isOne) {
          return;
        }
        this.isOne = !this.isOne;
        this.isTwo = false;
        this.isThree = false;
        this.isFour = false;
      }
      if (type == 2) {
        if (this.isTwo) {
          return;
        }
        this.isTwo = !this.isTwo;
        this.isOne = false;
        this.isThree = false;
        this.isFour = false;
      }
      if (type == 3) {
        if (this.isThree) {
          return;
        }
        this.isThree = !this.isThree;
        this.isTwo = false;
        this.isOne = false;
        this.isFour = false;
      }
      if (type == 4) {
        if (this.isFour) {
          return;
        }
        this.isFour = !this.isFour;
        this.isThree = false;
        this.isTwo = false;
        this.isOne = false;
      }
    },
  },
};
</script>
<style scoped>
.bottom-tabbar {
  height: 15vmin;
  display: flex;
  justify-content: space-around;
}

.pic {
  width: 6vmin;
}
.label {
  color: #868f9e;
}
.active {
  color: #262626;
}

@media screen and (min-width: 600px) {
  .label {
    font-size: 3vmin;
  }
}
</style>
